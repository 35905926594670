<template>
  <div class="instructions-body">
    <secondary-block>
      <p class="instructions-intro">
        {{$t('instuctionsIntro')}}
      </p>
    </secondary-block>
    <v-row justify="center" class="btns-block">
      <v-col cols="6" align="center">
        <v-btn style="height: 36px !important;" class="instructions-button" v-html="$t('instructionsPage.templateFile')"/>
      </v-col>
      <v-col cols="6" align="center">
        <v-btn style="height: 36px !important;"  class="instructions-button" v-html="$t('instructionsPage.samplesAbstract')"/>
      </v-col>
      <v-col cols="6" align="center">
        <v-btn style="height: 36px !important;"  class="instructions-button" v-html="$t('instructionsPage.faq')"/>
      </v-col>
    </v-row>
    <h1>
      {{$t('instructionsPage.articleRequirements')}}
    </h1>
    <secondary-block>
      <h2>
        {{$t('instructionsPage.firstHeader')}}
      </h2>
      <br>
      <p v-html="$t('instructionsPage.block1.part1')"/>
      <p v-html="$t('instructionsPage.block1.part2')"/>
      <p v-html="$t('instructionsPage.block1.part3')"/>
      <p v-html="$t('instructionsPage.block1.part4')"/>
      <p v-html="$t('instructionsPage.block1.part5')"/>
      <p v-html="$t('instructionsPage.block1.part6')"/>

      <h2>
        {{$t('instructionsPage.secondHeader')}}
      </h2>
      <div v-if="$t('instructionsPage.secondHeader')">
        <br>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block2.part1-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block2.part1-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block2.part2-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block2.part2-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block2.part3-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block2.part3-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block2.part4-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block2.part4-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block2.part5-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block2.part5-2')"/>
          </v-col>
        </v-row>
      </div>


      <h2>
        {{$t('instructionsPage.thirdHeader')}}
      </h2>
      <div v-if="$t('instructionsPage.thirdHeader')">
        <br>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block3.part1-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block3.part1-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block3.part1-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block3.part2-2')"/>
          </v-col>
        </v-row>
      </div>


      <h2>
        {{$t('instructionsPage.fourthHeader')}}
      </h2>
      <br>
      <div v-if="$t('instructionsPage.fourthHeader')">
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block4.part1-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block4.part1-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block4.part2-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block4.part2-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block4.part3-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block4.part3-2')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>
              <strong>
                {{$t('instructionsPage.block4.part4-1')}}
              </strong>
            </p>
          </v-col>
          <v-col>
            <p v-html="$t('instructionsPage.block4.part4-2')"/>
          </v-col>
        </v-row>
        <br>
        <p>
          {{$t('instructionsPage.block4.part5')}}
        </p>
      </div>


      <h2>
        {{$t('instructionsPage.fifthHeader')}}
      </h2>
      <div v-if="$t('instructionsPage.fifthHeader')">
        <br>
        <p v-html="$t('instructionsPage.block5.part1')"/>
        <p v-html="$t('instructionsPage.block5.part2')"/>
        <p v-html="$t('instructionsPage.block5.part3')"/>
        <p v-html="$t('instructionsPage.block5.part4')"/>
        <p v-html="$t('instructionsPage.block5.part5')"/>
        <p v-html="$t('instructionsPage.block5.part6')"/>
        <p v-html="$t('instructionsPage.block5.part7')"/>
        <p v-html="$t('instructionsPage.block5.part8')"/>
        <p v-html="$t('instructionsPage.block5.part9')"/>
        <p v-html="$t('instructionsPage.block5.part10')"/>
        <p v-html="$t('instructionsPage.block5.part11')"/>
        <v-row class="btns-block" justify="center">
          <v-col cols="6" class="btn-col">
            <v-btn class="from-lang-btn" href="http://translit.kh.ua/#lat/passport">
              {{$t('instructionsPage.block5.button1')}}
            </v-btn>
          </v-col>
          <v-col cols="6" class="btn-col">
            <v-btn class="from-lang-btn" href="http://ru.translit.net/?account=mvd">
              {{$t('instructionsPage.block5.button2')}}
            </v-btn>
          </v-col>
        </v-row>
      </div>


      <h2>
        {{$t('instructionsPage.sixthHeader')}}
      </h2>
      <div v-if="$t('instructionsPage.sixthHeader')">
        <br>
        <p v-html="$t('instructionsPage.block6.part1')"/>
        <p v-html="$t('instructionsPage.block6.part2')"/>
        <p v-html="$t('instructionsPage.block6.part3')"/>
        <p v-html="$t('instructionsPage.block6.part4')"/>
        <p v-html="$t('instructionsPage.block6.part5')"/>
        <p v-html="$t('instructionsPage.block6.part6')"/>
        <p v-html="$t('instructionsPage.block6.part7')"/>
      </div>


      <h2>
        {{$t('instructionsPage.seventhHeader')}}
      </h2>
      <div v-if="$t('instructionsPage.seventhHeader')">
        <br>
        <p v-html="$t('instructionsPage.block7.part1')"/>
        <p v-html="$t('instructionsPage.block7.part2')"/>
        <p v-html="$t('instructionsPage.block7.part3')"/>
        <p v-html="$t('instructionsPage.block7.part4')"/>
        <p v-html="$t('instructionsPage.block7.part5')"/>
        <p v-html="$t('instructionsPage.block7.part6')"/>
        <p v-html="$t('instructionsPage.block7.part7')"/>
        <p v-html="$t('instructionsPage.block7.part8')"/>
        <p v-html="$t('instructionsPage.block7.part9')"/>
      </div>

    </secondary-block>

    <p class="caution">
      <strong>
        {{$t('instructionsPage.warning')}}
      </strong>
    </p>
    <h2 class="send-to" v-html="$t('instructionsPage.sendTo')"/>

  </div>
</template>

<script>
import SecondaryBlock from "@/components/SecondaryBlock";
export default {
  name: "InstructionsForAuthors",
  components: {SecondaryBlock}
}
</script>

<style scoped>
.btns-block{
  margin: 2% auto;
}
.btn-col{
  display: flex;
  align-items: center;
  justify-content: center;
}
.from-lang-btn{
  background-color: #1DE9B6 !important;
  padding: 7% !important;
}
.instructions-intro{
  font-weight: bold;
  text-align: center;
}
.instructions-body{
  margin-top: 5%;
  margin-bottom: 2%;
}
.instructions-button{
  background-color: #1DE9B6 !important;
  padding: 10% !important;
}
.caution{
  margin-top: 5%;
  margin-bottom: 2%;
  text-align: center;
}
.send-to{
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
</style>
